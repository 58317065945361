import { useRef, useState } from "react"
import Button from "./Button"
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "../../constants/routes";

const FlipCard = ({ title, text, img }) => {
  const [showBack, setShowBack] = useState(false)
  const navigate = useNavigate();
  const handleMouseOver = () => {
    setShowBack(true)
  }

  const handleMouseOut = () => {
    setShowBack(false)
  }

  const cardRef = useRef(null)
  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="h-80 mb-6 w-60">
      {showBack ? (
        <div className="bg-darkRed h-full flex flex-col justify-center items-center">
          <p className="font-roboto text-xl p-4 text-center">{text}</p>
          <Button onClick={() => navigate(ROUTES.map)} text="ПРЕДПОЛОЖИ" variant="md" />
        </div>
      ) : (
        <div className="h-full bg-cover" style={{ 'backgroundImage': `url("${img}")` }} />
      )}
      <h3 className="text-3xl text-center pt-2 uppercase">{title}</h3>
    </div>
  )
}

export default FlipCard