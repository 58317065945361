import { useEffect, useState, } from "react"
import { useAgeCheck } from "../hooks/useAgeCheck"
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import { ROUTES } from "../constants/routes";
import { getCookieValue } from "../helpers/cookieHelper";
import StandartPageLayout from "../components/layout/StandartPageLayout";
import Button from "../components/common/Button";
import InputText from "../components/common/InputText";


const AgeCheck = () => {
  const [date, setDate] = useState({ day: '', month: '', year: '' })
  const [isValid, setIsValid] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const { isAdult, setAgeCookie, setAgeSession } = useAgeCheck()

  const navigate = useNavigate();

  if (sessionStorage.ageCheckPass === 'true' || getCookieValue('ageCheckPass') === true) {
    return <Navigate to={ROUTES.home} replace />
  }
  const handleSubmit = () => {
    navigate(ROUTES.home)
    if (isAdult({ ...date })) {
      rememberMe ? setAgeCookie(true) : setAgeSession(true)
      // debugger
    } else {
      setIsValid(false)
    }
  }

  const onValidate = (e) => {
    const { value, name } = e.target
    if (!Number.isInteger(+value)) {
      return setIsValid(false)
    }
    if (value <= 0) {
      return setIsValid(false)
    }
    if (name === 'day') {
      if (value > 31) {
        return setIsValid(false)
      }
    }
    if (name === 'month') {
      if (value > 12) {
        return setIsValid(false)
      }
    }

    if (name === 'year') {
      if (value < 1900) {
        return setIsValid(false)
      }
    }

    return setIsValid(isAdult(date))
  }
  const handleDateChange = (e) => {
    setDate((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
    onValidate(e)
  }
  return (

    <StandartPageLayout>
      <div className="flex flex-1 justify-center items-center h-full gap-16">
        <div>
          <img src="./LaPuertaRoja-Logo.svg" alt="La Puerta Roja Logo" />
        </div>
        <div>
          <h3 className="text-4xl">ИМАШ ПРЕДПОЛОЖЕНИЕ<br /> НАКЪДЕ ВОДИ LA PUERTA ROJA?</h3>
          <p className="py-5 text-xl font-roboto">Трябва да си навършил/а 18 години,<br/> за да продължиш.</p>
          <div className="flex gap-4">
            <InputText classes="text-center" name="day" type="text" inputMode="numeric" maxLength={2} pattern="\d*" value={date.day} placeholder="ДД" onChange={handleDateChange} onBlur={onValidate} />

            <InputText name="month" classes="text-center" inputMode="numeric" maxLength={2} pattern="\d*" value={date.month} placeholder="ММ" onChange={handleDateChange} onBlur={onValidate} />
            <InputText name="year" classes="text-center" inputMode="numeric" maxLength={4} pattern="\d*" value={date.year} placeholder="ГГГГ" onChange={handleDateChange} onBlur={onValidate} />


          </div>
          <div className="py-5">
          <input type="checkbox" name="rememberme" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
          <label for="rememberme" className="text-white text-xl font-roboto pl-4">Запомни ме</label>

          </div>
          <Button onClick={() => handleSubmit()} text="НАПРЕД" disabled={!isValid} />


        </div>
      </div>
    </StandartPageLayout>

  )
}

export default AgeCheck