import { useEffect, useState } from "react"
import { getCookieValue } from "../helpers/cookieHelper"
import { DateTime, Interval } from "luxon"

export const useAgeCheck = () => {
  const [ageAproved, setAgeAproved] = useState(false)
  useEffect(() => {
    if (sessionStorage.ageCheckPass === 'true' || getCookieValue('ageCheckPass') === true) {
      setAgeAproved(true)
    }
  }, [])

  const isAdult = ({ day, month, year }) => {
    if (day > 0 && month > 0 && year > 0) {
      const dt = DateTime.fromObject({ month, day, year })
      if (dt.isValid) {
        const interval = Interval.fromDateTimes(dt, DateTime.now()).toDuration('years')
        return !interval.invalid && interval.values.years >= 18
      }
    }
    return false
  }


  const setAgeCookie = () => {
    const dt = DateTime.now().plus({ month: 1 })
    const nextMonth = new Date(dt)
    document.cookie = `ageCheckPass=true; expires=${nextMonth}; SameSite=Lax;`
  }

  const setAgeSession = () => {
    sessionStorage.ageCheckPass = true
  }
  return { ageAproved, isAdult, setAgeCookie, setAgeSession }
}

