import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { useAgeCheck } from '../../hooks/useAgeCheck';
import { useContext } from 'react';
import { AgeAprovedContext } from '../../context/AgeAprovedContext';
import { getCookieValue } from '../../helpers/cookieHelper';

const AuthorizedRoute = ({ children }) => {
  let location = useLocation()
  console.log('location0', location)
  // const { ageAproved } = useAgeCheck()
  // const ageAproved = useContext(AgeAprovedContext)
  const ageAproved = sessionStorage.ageCheckPass === 'true' || getCookieValue('ageCheckPass') === true
  if (!ageAproved) {
    return <Navigate to={ROUTES.ageGate} replace />;
  }

  if (location.pathname === ROUTES.ageGate) {
    return <Navigate to={ROUTES.home} replace />
  }
  return children
}

export default AuthorizedRoute