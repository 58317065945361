import { useEffect } from "react"

const StandartPageLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('bg-pattern')

    return () => {
      document.body.classList.remove('bg-pattern')
    }
  }, [])
  return (

    // <div className="container mx-auto px-4 bg-red h-full">
    <div className="container bg-red h-full max-w-4xl p-10">

      {children}
    </div>
    // </div>
  )
}
export default StandartPageLayout