import axios from "axios"
import { useEffect, useState } from "react"
import InputText from "../components/common/InputText"
import Button from "../components/common/Button"
import StandartPageLayout from "../components/layout/StandartPageLayout"


const Submit = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subscribe: false,
  })
  // useEffect(() => {
  //   for(let i =0; i<10;i++) {
  //     handleSubmit(1)
  //   }
  // },[])

  const handleInput = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }

  const handleCheck = (e) => {
    setFormData((prev) => ({
      ...prev,
      subscribe: !prev.subscribe
    }))
  }
  const onValidate = () => { }
  const handleSubmit = (e) => {
    e.preventDefault()
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute('6LdhAGkpAAAAAJA_EtaRpoIxvC6uTb5EU1zt1Omc', { action: 'submit' }).then(function (token) {
        // Add your logic to submit to your backend server here.
        // console.log('token', token)
        axios.postForm('http://localhost:80/wp-json/contact-form-7/v1/contact-forms/12/feedback', {
          'your-name': formData.name,
          'your-email': formData.email,
          'your-phone': formData.phone,
          'subscribe': formData.subscribe,
          '_wpcf7_unit_tag': 'wpcf7-f12-p2-o1',
          "_wpcf7_recaptcha_response": token
        }).then(r => console.log('response', r)).catch(e => console.log('error', e))
        console.log(e.target)
      });
    });

  }
  return (
    <StandartPageLayout>
      <div className="container mx-auto px-4 bg-red h-full">
        <form onSubmit={handleSubmit} method="POST">
          <div className="">
            <label className="text-white uppercase text-xl my-2 block" for="name">Име и фамилия</label>
            <div>
              <InputText name="name" type="text" value={formData.name} onChange={handleInput} onBlur={onValidate} classes="w-full text-left" />
            </div>

          </div>

          <div className="">
            <label className="text-white uppercase text-xl my-2 block" for="email">Имейл</label>
            <div>
              <InputText name="email" value={formData.email} onChange={handleInput} onBlur={onValidate} classes="w-full text-left" />

            </div>
          </div>

          <div className="">
            <label className="text-white uppercase text-xl my-2 block" for="phone" >Телефон</label>
            <div>
              <InputText name="phone" value={formData.phone} onChange={handleInput} onBlur={onValidate} classes="w-full" />
            </div>
          </div>
          <div>
          <input type="checkbox" name="subscribe" checked={formData.subscribe} onChange={handleCheck} />
          </div>
          <Button onClick={handleSubmit} text="ИЗПРАТИ" disabled={!true} />
          {/* <button type="submit">Submit</button> */}
        </form>

      </div>

    </StandartPageLayout>
  )
}

export default Submit