const Button = ({ text, disabled, onClick, variant }) => {
  const types = {
    md: "bg-white hover:cursor-pointer hover:opacity-75 disabled:opacity-75 disabled:cursor-not-allowed text-red text-base h-8 w-28",
    default: "bg-white hover:cursor-pointer hover:opacity-75 disabled:opacity-75 disabled:cursor-not-allowed text-red text-3xl h-16 w-52"
  }

  return (
    <button
      className={types[variant] || types.default}
      disabled={disabled}
      onClick={onClick}>
      {text}
    </button>
  )
}

export default Button