import Clue from "../components/clue/Clue"
import StandartPageLayout from "../components/layout/StandartPageLayout"

const Clues = () => {
  return (
    <StandartPageLayout>
      <h1 className="text-7xl uppercase pt-16">Жокери</h1>
      <p className="font-roboto text-2xl py-9">Тук можеш да откриеш изминалите жокери, за да направиш по-добро предположение накъде води La Puerta Roja. Следи страницата ни в социалните мрежи, за да не изпуснеш новите.</p>

      <Clue />
    </StandartPageLayout>
  )
}

export default Clues