const InputText = ({ onChange, onValidate, name, inputMode, maxLength, placeholder, value, classes }) => {

  const inputWidth = {
    2: 'w-16',
    4: 'w-28'
  }

  return (
    <input
      className={`${inputWidth[maxLength] || 'w-auto'} h-16 px-1 text-3xl placeholder:text-red placeholder:text-3xl ${classes}`}
      name={name}
      type="text"
      inputMode={inputMode}
      maxLength={maxLength}
      pattern="\d*"
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onValidate}
    />
  )
}

export default InputText