import { Link } from "react-router-dom"
import { ROUTES } from "../../../constants/routes"
import { AgeAprovedContext } from "../../../context/AgeAprovedContext"
import { useContext } from "react"

const Navigation = () => {
  const ageAproved = useContext(AgeAprovedContext)
  return (
    <div className="px-12 py-4 flex gap-6 justify-between bg-red relative  w-screen items-center shadow-menu max-w-full">
      <Link to={ROUTES.home}>
        <img src="LaPuertaRoja-Logo.svg" alt="Roja Logo" className="h-16 py-2" />
      </Link>

      <div className="flex gap-16 text-3xl ">
        <Link to={ROUTES.map} className="uppercase">Предположи</Link>
        <Link to={ROUTES.home} className="uppercase">Home</Link>
        <Link to={ROUTES.ageGate} className="uppercase">Age</Link>
        <Link to={ROUTES.prizes} className="uppercase">Награди и победители</Link>
        <Link to={ROUTES.clues} className="uppercase">Жокери</Link>
        <Link to={ROUTES.form} className="uppercase">Форм</Link>

      </div>
      {ageAproved ? ' Age Aproved' : 'Not aproved'}
    </div>
  )
}

export default Navigation