const HomePageLayout = ({ children }) => {

  return (
    <div className="">
      <div className="absolute top-0 left-0 w-1/3 -z-10 bg-continents bg-no-repeat bg-cover bg-right h-full" />
      <div className="container mx-auto">

        {children}
      </div>
      <div className="absolute top-0 right-0 w-1/3 -z-10 bg-continents bg-no-repeat bg-cover bg-left h-full" />
    </div>
  )
}
export default HomePageLayout