import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Stage, Layer, Text, Image } from 'react-konva';
import useImage from 'use-image';
import { isInBound } from '../helpers/isInBound';
import { getCookieValue } from '../helpers/cookieHelper';
import { DateTime, Interval } from "luxon"

function KonvaVer() {
  const [coordinates, setCoordinates] = useState({ x: 20, y: 50 })
  const [isDragging, setIsDragging] = useState(false)
  const [size, setSize] = useState({})
  const containerRef = useRef(null)

  useEffect(() => {
    const initialCoordinates = getCookieValue('chosenLocation')
    if (initialCoordinates) {
      console.log(initialCoordinates.x)
      // console.log(JSON.parse(initialCoordinates))
      setCoordinates(initialCoordinates)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current?.offsetHeight && containerRef.current?.offsetWidth) {
        const scale = { x: containerRef.current.offsetWidth / size.x, y:  containerRef.current.offsetWidth / 2 / size.y }
        if ((!isNaN(scale.x) && scale.x !== 1) || (!isNaN(scale.y) && scale.y !== 1)) {
          setCoordinates((prev) => { return { x: prev.x * scale.x, y: prev.y * scale.y } })
        }
        setSize({
          x: containerRef.current.offsetWidth,
          y: containerRef.current.offsetWidth / 2
        })
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size.x, size.y])


  const [image] = useImage('../continents-right.svg');
  const [pin] = useImage('../pin.svg');

  const setCookieCoordinates = () => {
    const dt = DateTime.now().plus({ month: 1 })
    const nextMonth = new Date(dt)
    document.cookie = `chosenLocation=${JSON.stringify({x: coordinates.x, y: coordinates.y})}; expires=${nextMonth}; SameSite=Lax;`
  }



  return (
    <div className='container w-full h-screen' ref={containerRef}>
      <div className='tracker' />
      <Stage width={size.x} height={size.x / 2} >
        <Layer >
          {/* <Map /> */}
          <Image image={image} width={size.x} height={size.x / 2} />
          <Text
            text={`X: ${coordinates.x} Y: ${coordinates.y}`}
          />
          <Image
            image={pin}
            width={50}
            height={50}
            x={coordinates.x}
            y={coordinates.y}
            draggable
            // fill={isDragging ? 'green' : 'black'}
            onDragStart={() => {
              setIsDragging(true)
            }}
            onDragEnd={(e) => {
              const { newX, newY } = isInBound(e.target.x(), e.target.y(), size.x, size.y)
              setIsDragging(false)
              setCoordinates({ x: newX, y: newY })
            }}
          />
        </Layer>
      </Stage>
      <button className='btn' onClick={() => { setCookieCoordinates() }}>Predpoloji</button>
    </div>
  );
}

export default KonvaVer;