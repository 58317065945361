import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Map from "../pages/Map";
import AgeCheck from "../pages/AgeCheck";
import AuthorizedRoute from "../components/common/AuthorizedRoute";
import VerifiedRoute from "../components/common/VerifiedRoute";
import { ROUTES } from "../constants/routes";
import { RootLayout } from "../components/layout/RootLayout";
import Submit from "../pages/Submit";
import Prizes from "../pages/Prizes";
import Clues from "../pages/Clues";

export const router = createBrowserRouter([
  {
    path: ROUTES.ageGate,
    element: <AgeCheck />
  },
  {
    path: '/',
    element: <RootLayout />,
    // errorElement: <ErrorBoundary />,
    children: [
      {
        path: ROUTES.home,
        element: <AuthorizedRoute><Home /></AuthorizedRoute>
      },

      {
        path: ROUTES.map,
        element: <AuthorizedRoute><Map /></AuthorizedRoute>
      },
      {
        path: ROUTES.form,
        element: <AuthorizedRoute><Submit /></AuthorizedRoute>
      },
      {
        path: ROUTES.prizes,
        element: <AuthorizedRoute><Prizes /></AuthorizedRoute>
      },
      {
        path: ROUTES.clues,
        element: <AuthorizedRoute><Clues /></AuthorizedRoute>
      },
    ]
  }
])