import axios from "axios"
import { useEffect, useState } from "react"
import FlipCard from "../common/FlipCard"


const Clue = () => {
  const [clues, setClues] = useState()
  useEffect(() => {
    axios.get('http://localhost:80/wp-json/wp/v2/clues?filter[orderby]=id&order=asc')
      .then(res => {
        setClues(res.data)
        console.log(res)
      })

  }, [])
  return (
    <>
      <div className="flex flex-row gap-10 justify-between flex-wrap container mx-auto">
        {clues?.map((clue) => {
          const imgSrc = clue.meta_box?.image_upload?.[0]?.full_url || ''
          const text = clue.meta_box?.['clue-text']
          return (
            <FlipCard key={clue.id} title={clue.title.rendered} img={imgSrc} text={text} />
          )
        })}

      </div>
    </>
  )
}

export default Clue