export const isInBound = (x, y, fieldX, fieldY) => {
  let newX = x, newY = y
  if (x <= 0) {
    newX = 0
  }
  
  if (y <= 0) {
    newY = 0
  }
  
  if (x > fieldX) {
    newX = fieldX - 30
  }
  
  if (y > fieldY) {
    newY = fieldY -30
  }

  return { newX, newY }
}