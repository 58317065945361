import StandartPageLayout from "../components/layout/StandartPageLayout"
import Button from '../components/common/Button'
import { ROUTES } from "../constants/routes"
import { useNavigate   } from 'react-router-dom';
import HomePageLayout from "../components/layout/HomePageLayout";

const Home = () => {
  const navigate = useNavigate();

  return (
    // <StandartPageLayout>
    <HomePageLayout>

      <div className="container mx-auto max-w-3xl px-4 py-8">
        <h3 className="text-6xl">НАКЪДЕ ВОДИ LA PUERTA ROJA?</h3>
        <p className="font-roboto text-[22px] pt-8">Само след 20 дни голямата червена врата в центъра на твоя град ще се отвори към един нов свят, пълен със забавление, партита, музика и вкусна храна. Опитай се да познаеш към кой град води и можеш да спечелиш страхотни награди.</p>
        <div className="flex justify-between my-9 gap-4">
          <div className="flex basis-1/3 sitems-center">
            <img src="step1.svg" alt="Step 1" className="mr-4" />
            <div>
              <p className="text-xl">ПРЕДПОЛОЖИ</p>
              <p className="font-roboto text-base">кой е града, към който води la puerta roja</p>
            </div>
          </div>
          <div className="flex basis-1/3 items-center">
            <img src="step2.svg" alt="Step 2" className="mr-4" />
            <div>
              <p className="text-xl">ПРОВЕРЯВАЙ</p>
              <p className="font-roboto text-base">за жокери всеки ден и прави нови предположения</p>
            </div>
          </div>
          <div className="flex basis-1/3 items-center">
            <img src="step3.svg" alt="Step 3" className="mr-4" />
            <div>
              <p className="text-xl">СЛЕДИ</p>
              <p className="font-roboto text-base">дали печелиш една от 15 награди</p>
            </div>
          </div>

        </div>
        
        <Button onClick={() =>  navigate(ROUTES.map)} text="ПРЕДПОЛОЖИ" />
      </div>
    </HomePageLayout>
    // </StandartPageLayout>
  )
}

export default Home