import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import GptVers from './components/gpt-version';
import KonvaVer from './components/konva';
import Vanilla from './components/vanilla';
import Konva2 from './components/konva2';
import { RouterProvider } from 'react-router-dom';
import { router } from './helpers/router';
import { AgeAproved } from './context/AgeAprovedContext';

function App() {

  useEffect(() => {
    // <script src="https://www.google.com/recaptcha/api.js"></script>
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = 'https://www.google.com/recaptcha/api.js?render=6LdhAGkpAAAAAJA_EtaRpoIxvC6uTb5EU1zt1Omc'
      body.appendChild(script)
      
    }
  }, [])

  return (
    <>
      {/* <Clues /> */}
      
        <RouterProvider router={router} />
      {/* </AgeAproved> */}
      {/* <GptVers /> */}
      {/* <KonvaVer /> */}
      {/* <Vanilla /> */}
      {/* <Konva2 /> */}
    </>
  );
}

export default App;