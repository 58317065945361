import { createContext, useContext, useState, useEffect } from 'react';
import { getCookieValue } from '../helpers/cookieHelper';

export const AgeAprovedContext = createContext(null)

export const AgeAproved = ({children}) => {
  const [ageAproved, setAgeAproved] = useState(false)
  useEffect(() => {
    if (sessionStorage.ageCheckPass === 'true' || getCookieValue('ageCheckPass') === true) {
      setAgeAproved(true)
    }
  }, [])
  
  // debugger
  return (
    <AgeAprovedContext.Provider value={ageAproved}>
      {children}
    </AgeAprovedContext.Provider>
  )
}